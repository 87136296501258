import React, { useState } from "react";
import { useTranslation, LANGUAGES } from "../translations/i18next";
import useClickAway from "../useClickAway";

const Item = ({ code, name, onClick }) => {
  return (
    <div
      class="btn btn-dark rounded d-flex align-items-center justify-content-center"
      onClick={onClick}
    >
      <img src={`/flags/${code}.png`} height="25" width="25" alt={name} />
      <span className="ml-2">{name}</span>
    </div>
  );
};

const LanguagePicker = () => {
  const { changeLanguage, currentLanguage } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useClickAway(() => setIsOpen(false));
  const onLangSelect = (code) => {
    changeLanguage(code);
    setIsOpen(false);
  };

  return (
    <div style={{ position: "relative" }} ref={menuRef}>
      <Item
        code={currentLanguage.code}
        name={currentLanguage.name}
        onClick={() => setIsOpen((prev) => !prev)}
      />
      {isOpen && (
        <div
          style={{
            position: "absolute",
            bottom: "110%",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <div class="list-group bg-dark p-1" style={{ gap: "4px" }}>
            {LANGUAGES.filter(({ code }) => code !== currentLanguage.code).map(
              ({ code, name }) => (
                <Item
                  key={code}
                  code={code}
                  name={name}
                  onClick={() => onLangSelect(code)}
                />
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default LanguagePicker;

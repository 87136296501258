import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from "react-query";
import { StoreProvider } from "sg-frontend-shared/src/hooks/useStorage";
import { AuthProvider } from "sg-frontend-shared/src/hooks/useAuth";
import { AxiosInstanceProvider } from 'sg-frontend-shared/src/hooks/useAssetAPI';

import { userPoolAuthURL, userPoolClientId } from './config';

import './App.css';

import HomeLayout from './routes/HomeLayout';
import ProtectedLayout from './routes/ProtectedLayout';

import Home from './routes/Home';
import Login from './routes/Login';
import Logout from './routes/Logout';
import LoginCallback from './routes/LoginCallback';
import Install from './routes/Install';
import Start from './routes/Start';
import GuideDoc from './routes/GuideDoc';
import { TranslationProvider } from "./translations/i18next";

const queryClient = new QueryClient();


function App() {
  return (
    <div>
      <Router>
        <AuthProvider
          userPoolAuthURL={userPoolAuthURL}
          userPoolClientId={userPoolClientId}
        >
          <StoreProvider initialState={{ counter: 0 }}>
            <AxiosInstanceProvider>
              <QueryClientProvider client={queryClient}>
                <TranslationProvider>
                  <Routes>
                    <Route element={<HomeLayout />}>
                      <Route exact path="/" element={<Home />} />
                      <Route exact path="/oauth" element={<Login />} />
                      <Route path="/oauth/callback" element={<LoginCallback />} />
                      <Route path="/doc" element={<GuideDoc />} />
                    </Route>
                    <Route element={<ProtectedLayout />}>
                      <Route path="/logout" element={<Logout />} />
                      <Route path="/install" element={<Install />} />
                      <Route path="/start" element={<Start />} />
                    </Route>
                  </Routes>
                </TranslationProvider>
              </QueryClientProvider>
            </AxiosInstanceProvider>
          </StoreProvider>
        </AuthProvider>
      </Router>
    </div>
  );
}


export default App;

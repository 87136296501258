import * as Functions from './Functions';

// This use for step by step guide to install

export function Hardware1(t){
  return(
    <div>
      <div className="content-box">
      <div className="content-title">1. {t("guideInfo.checkAvailability")}</div>
        <div className="ml-1">       
            <i className="fa fa-check"></i> {t("guideInfo.heatPumpUnit")} <br/>
            <i className="fa fa-check"></i> {t("guideInfo.sgTHouseTempSensor")} <br/>
            <i className="fa fa-check"></i> {t("guideInfo.powerAdapterToSGA")} <br/>
            <i className="fa fa-check"></i> {t("guideInfo.cableConnectingSgaHeatPump")} <br/> 
            <div className='text-center mt-2'>
              <img src="/img/SG-complete.png" width="90%"></img>
            </div>
        </div>
      </div>

      <div className="content-title mt-3 content-box">
        2. {t("guideInfo.checkPowerOutlet")}
      </div>

      <div className="content-title mt-3 content-box">
        3. {t("guideInfo.switchOnSGA")} 
        <div className='text-center mt-2 ml-1'>
            <img src="/img/SG-onoff.png" width="90%"></img>
        </div>
      </div>

      <div className="content-title mt-3 content-box">
        4. {t("guideInfo.insertBatteriesSensor")}
        <div className='text-center mt-2 ml-1'>
            <img src="/img/SG-T-batt-1.png" width="90%"></img>
        </div>
        <div className='text-center mt-2 ml-1'>
            <img src="/img/SG-T-batt-2.png" width="90%"></img>
        </div>
      </div>

      <div className="mt-3 content-info hidden">
        <i className="fa fa-info-circle text-2 mr-1"></i>     
      </div>

    </div>);
}


export function Hardware2(t){
  return(
    <div>

      <div className="content-box">
        <div className="content-title">
          1. {t("guideInfo.placeUnitConnectPower")}
        </div>
      </div>

      <div className="content-box  mt-3">
        <div className="content-title">    
          2. {t("guideInfo.check4GCoverage")}
        </div>
        <div>
          <span className="color-green text-big bold">&#9473;</span> <span dangerouslySetInnerHTML={{__html: t("guideInfo.greenGoodCoverage")}}/><br/>
          <span className="color-orange text-big bold">&#9473;</span> <span dangerouslySetInnerHTML={{__html: t("guideInfo.orangeSufficientCoverage")}}/><br/>
          <span className="color-red text-big bold">&#9473;</span> <span dangerouslySetInnerHTML={{__html: t("guideInfo.redInsufficientCoverage")}}/><br/>
        </div>
        <img src="/img/sa-hole.jpg" className="mt-2" alt="sa-hole" title="sa-hole" width="100%" />
      </div>

      <div className="mt-3 p-2 content-info text-grey ">
        <i className="fa fa-info-circle text-2"></i>&nbsp;
        <i>
        {t("guideInfo.redLedRelocate")}
        </i>
      </div>

    </div>
  );
}

 

export function Verify(t){
  return(
    <div>

      <div className="content-box">
        <div className="content-title">
        {t("guideInfo.verifyInstallation")}
        </div>
       
      </div>

      <div className="content-box mt-3" dangerouslySetInnerHTML={{__html: `<strong>1.</strong> ${t("guideInfo.sgAMustBeConnected")}`}}/>

     <div className="content-box mt-3">        
           <p dangerouslySetInnerHTML={{__html: `<strong>2.</strong> ${t("guideInfo.verifyOutdoorTemp")}`}}/>
          <div className='ml-3'>
              <p className='bold'><u>{t("guideInfo.doFollowing")}</u></p>
              <p>
              {t("guideInfo.simulateOutdoorTemperature")}
              </p>
              {t("guideInfo.incorrectValuesSwapLeads")}
          </div>         
     </div>

     <div className="content-box mt-3" dangerouslySetInnerHTML={{__html: `<strong>3.</strong> ${t("guideInfo.externalInputsActivation")}`}}/>

    </div>
  );
}


export function Support({ title, description }) {
  return (
    <div className="p-2 relative">
      <h5>
        <i className="fa fa-headphones" aria-hidden="true"></i> {title}
      </h5>
      <div dangerouslySetInnerHTML={{ __html: description }} />
    </div>
  );
}

export function Footer(props) {
  return (
    <div className="w-50 mt-3 mb-4 mx-auto trans text-center">
      <img src="/img/logo-w.png" width="100%" className="w-100" />
    </div>
  )
}

export function DeviceGuide(docId, props){

  const {t} = props;

  const CloseWarningModal = () => {
    props.setWarningModal(false);
    console.log("props.WarningModal", props.WarningModal);
  }

  const StepData = (section) => {
    var resp = '';
    switch(section) {
      case "dem" : resp = props.stateGuideDem; break;
      case "cable" : resp = props.stateGuideCable; break;
      case "inst" : resp = props.stateGuideInst; break;
      case "drift" : resp = props.stateGuideDrift; break;
      case "end" : resp = props.stateGuideEnd; break;
      case "first" : resp = props.stateGuideFirst; break;
      default: resp = ""; break;
    }
    return resp;
  }

  const NextStep = () => {
    const countUp = parseInt(props.stateStepGuide + 1);
    props.setStepGuide(countUp);
    props.ScrollToTop();
  }


  const BeforeStep = () => {
    const countUp = parseInt(props.stateStepGuide - 1);
    props.setStepGuide(countUp);
  }

  // Check if list have img
  const ViewImg = (id, img) =>{
    const imgUrl = '/guide/'+id+'/'+img;
    var resp = '';
    if(img){
      resp = '<img src='+imgUrl+' width="100%" className="mt-2 mx-auto w-50 w-max-100">';
    }
    return Functions.renderHTML(resp);
  }

  const StepList = (section, title, step) =>{
    //const listType = Functions.QueryValue('type');
    const viewWarningModal = props.WarningModal;
    const viewStep = props.stateStepGuide;
    const viewBefore = step - 1;
    const viewNext = step + 1;

    const itemClass = (section === 'first') ? 'relative p-3 mb-3  content-box':'relative p-3 mb-3 content-box';
    const itemTxt = t("install.nextStep");
    const data = StepData(section);
    //apidata.sort((a, b) => a.pos - b.pos);


    const stepPos = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P'];
    return(
      <div className="" id={"step-"+step}>
        {viewStep === 1 && viewWarningModal && 
          <div className="modal" style={{display:'block'}}>
                <div className="modal-dialog">          
                      <div className='content-box modal-content text-center' style={{       
                              marginTop:'5rem',
                              fontSize:'1em',
                              backgroundColor:'#FF0000', 
                              color:'#FFFFCC'}}>
                              <h3 className='bold'>
                                <i className='fa fa-exclamation-triangle'></i> <i className='fa fa-flash'></i> 
                                <br/>
                                {t("guideInfo.attention")}
                              </h3>
                              <div className='no-wrap bold'>
                              {t("guideInfo.importantTurnOffMainSwitch")} <i className='fa fa-plug'></i>
                              </div>
                              <span dangerouslySetInnerHTML={{__html: t("guideInfo.heatPumpBeforeInstall")}}/>

                              <div className="text-center">
                                <div onClick={()=>CloseWarningModal()} className="pointer btn btn-border mt-3 mx-auto">{t("guideInfo.understood")}</div>
                              </div>
                        </div>

               
                </div>
             </div>
        }

        {viewStep === step &&
          <div className="">           

            { data &&
              <>
                {
                  data.map((item, i) => (
                    <div className={itemClass} key={i}>
                      {i === 0 &&  
                        <>
                          {step === 0 ?
                                    (
                                    <div className="text-center mb-2 line">
                                        <h5 className="bold">
                                          {title}
                                        </h5>
                                    </div>
                                    ):(
                                      <div className="text-center mb-2 line">
                                      <h5 className="bold">
                                          {viewStep}. {title}
                                      </h5>
                                  </div>
                                    )
                            }
                          </>
                        }

                        <div className="bold d-flex">                       
                            {section === 'first' ?
                              (<>                       
                                <div className="text-center hidden"><h3>{item.title}</h3></div>
                              </>):(
                                <div className="text-2 bold">
                                  <div><font className="">{stepPos[i]}.</font> {item.title}</div>
                                </div>
                              )
                            }
                        </div>
                        <div className="">{Functions.renderHTML(item.desc)}</div>
                        <div className="">{ViewImg(docId, item.img)}</div>
                    </div>
                  ))
                }
              </>
            }

            <div className="d-flex mb-4">
              {Number(viewNext) === 4 ? (<>
                  {props.BtnNext('next')}
                </>):(
                <>
                  <div  onClick={()=>NextStep()} className="btn btn-primary bold btn-block p-3 mt-3 w-100  no-wrap ml-auto">{itemTxt} &nbsp;&nbsp;<i className="fa fa-angle-right"></i></div>
                </>
              )}
            </div>
          </div>
        }
      </div>
    );
  }


  return(
    <div>
      { docId ? (
        <div>
          {StepList('first', props.titleDeviceModel, 0)}
          {StepList('dem', t("guideInfo.disassembleForInstall"), 1)}
          {StepList('cable', t("guideInfo.installHardware"), 2)}
          {StepList('inst', t("guideInfo.postInstallReset"), 3)}
          {StepList('drift', t("guideInfo.deployment"), 4)}
        </div>
      ):(
        <div>
          <div className="text-center"><h4>{t("guideInfo.noGuide")}</h4></div>

          <div className="mt-3 p-2 content-info text-center">
            <i>
              <i className="fa fa-info-circle text-2"></i>  <span dangerouslySetInnerHTML={{__html: t("guideInfo.installationGuideMissing", {model: props.titleDeviceModel})}}/>
            </i>
          </div>

          {props.BtnNext('next', t("install.nextStep"))}

        </div>
      )
      }
    </div>
  )
}

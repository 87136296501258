import { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useQuery, useQueryClient } from "react-query";
import { useAPI } from 'sg-frontend-shared/src/hooks/useAssetAPI';
import { useAuth } from "sg-frontend-shared/src/hooks/useAuth";
import * as GuideInfo from 'sg-frontend-shared/src/components/GuideInfo';

import * as Functions from 'sg-frontend-shared/src/components/Functions';

import { useTranslation } from "../translations/i18next.js";
import LanguagePicker from "../components/LanguagePicker.jsx";

export default function Start(){
  const {doLogout} = useAuth();
  const {t} = useTranslation();
  const TypeOfUser = "installer";
  var tenantArray = [];


  const [stateUseTenantId, setUseTenantId] = useState();
  const [stateUseTenantRole, setUseTenantRole] = useState();

  const [stateTenant, setTenant] = useState([]);
  const [stateTenantLoaded, setTenantLoaded] = useState();

  const [stateChangeTenant, setChangeTenant] = useState(false);

  const tenantId = (localStorage.getItem("tenantId")) ? localStorage.getItem("tenantId"):'';
  const tenantName = (localStorage.getItem("tenantName")) ? localStorage.getItem("tenantName"):'';
  const tenantCountry = (localStorage.getItem("tenantCountry")) ? localStorage.getItem("tenantCountry"):'';
  const searchQueryStorage = localStorage.getItem("searchQuery");
  const [stateSearch, setSearch] = useState(searchQueryStorage);

  // Call API
  const {deviceModelAPI, userInfo, callAPI} = useAPI();

  // empty APi cache
  const queryClient = useQueryClient();

  // empty APi cache
  useEffect(()=>{
    queryClient.removeQueries('tenant-asset');
    queryClient.removeQueries('tenant-devicemodel');
    queryClient.removeQueries('userInfo');

  },[queryClient]);


  // Get User tenantId
  const fetchUserInfo = async () => {
    const data = await userInfo();
    return data;
  };

  const UserData = useQuery('userInfo', fetchUserInfo);
  const tenantAccess = (UserData.isSuccess && UserData.data && UserData.data.access) ? UserData.data.access:'';
  //const tenantId = stateUseTenantId; //(UserData.isSuccess && UserData.data && UserData.data.access) ? UserData.data.access[0].tenantId:'';
  const tenantUserName = (UserData.isSuccess) ? UserData.data.userName:'';
  const tenantLength = (tenantAccess) ? tenantAccess.length:0;

  // getdeviceModels
  const fetchdeviceModel = async () => {
    const data = await deviceModelAPI({
      limit:10000
    });
    return data;
  };
  const deviceModel = useQuery('tenant-devicemodel', fetchdeviceModel);


  const SearchAction = (e) => {
    e.target.blur();
    console.log("SearchAction");
  }

  const searchbody = useRef();
  const ScrollToTopSearch = () => {
    window.scrollTo({behavior: 'smooth', top: searchbody.current.offsetTop })
  }

  const search = (e) =>{
    const s = e.target.value
    localStorage.setItem("searchQuery", s);

    setSearch(s);
  }

  const SelectTenant = async (id, viewname, country) => {
      setChangeTenant(false);
      setUseTenantId(id);
      localStorage.setItem("tenantCountry", country);
      localStorage.setItem("tenantRole", TypeOfUser);
      localStorage.setItem("tenantId", id);
      localStorage.setItem("tenantName", viewname);
      //window.history.pushState('', '', '');
  }

  const ChangeTenant = () => {
    setChangeTenant(true);
    setUseTenantId('');
    localStorage.setItem("tenantCountry", "");
    localStorage.setItem("tenantRole", "");
    localStorage.setItem("tenantId", "");
    localStorage.setItem("tenantName", "");

    // change url
    //window.history.pushState('', '', '');
  }


  useEffect(() => {
    const deviceObjects = (deviceModel?.data?.objects ?? []).filter(x => x.docId || Object.keys(x.docs ?? {}).length);
    if(deviceObjects.length > 0){    
      let setDeviceJson = JSON.stringify(deviceObjects, null, 2);
      localStorage.setItem("deviceModel", setDeviceJson);
    }
  }, [deviceModel?.data?.objects]);


  useEffect(() => {
    localStorage.setItem("userName", tenantUserName);
  }, [tenantUserName])


  useEffect(()=>{

    //
    // more than one group tenant
    if(tenantAccess && tenantAccess.length > 1 ) {

      tenantAccess.forEach(async (item, i) => {
        if(item.role === TypeOfUser) {
          // give access if installer role
          if(!stateUseTenantRole){setUseTenantRole(item.role);}

          const tenantId = (item && item.tenantId) ? item.tenantId:'';
          const tenantdata = await callAPI({resource: "/tenant/"+tenantId});
          if(tenantdata && tenantdata.tenantId) {
            tenantArray.push(tenantdata);
          }
        }
      });

      // load frontend if have data
      const stateTenantLength = (stateTenant && stateTenant.length) ? stateTenant.length:0;
      if(!stateTenantLoaded && stateTenantLength === 0 && tenantArray){
        setTimeout(()=>{
          setTenant(tenantArray);
          setTenantLoaded(true);
          console.log("load 1");

          setTimeout(()=>{
            setTenant(tenantArray);
            setTenantLoaded(true);
            console.log("load 2");
          },1000);

        },2500);
      }

      console.log("tenantLength", tenantLength, stateTenant.length, stateTenantLength, tenantArray);

    }else{

      // one group tenant
      const tId = (tenantAccess[0]) ? tenantAccess[0].tenantId:'';
      const role = (tenantAccess[0]) ? tenantAccess[0].role:'';
      if(role){setUseTenantRole(role);}
      const LoadTenantInfo = async (id) => {
        const tenantdata = await callAPI({
          resource: "/tenant/"+id
        });
        if(tenantdata && tenantdata.tenantId) {
          const name = (tenantdata.name) ? tenantdata.name : t("start.nameMissing");
          const viewname = (tenantdata && tenantdata.company) ? tenantdata.company:name;
          SelectTenant(tenantdata.tenantId, viewname);
          setTenantLoaded(true);
          console.log("tenantdata",tenantdata);
        }
      }
     if(tId) {LoadTenantInfo(tId);}
    }

    // if localstorage return back set id to state
    if(tenantId){
      SelectTenant(tenantId, tenantName, tenantCountry);
    }

    // eslint-disable-next-line
  },[tenantAccess, stateTenant ]);

  return(
      <div className="p-3 mx-auto w-50 auto-100 relative text-center">
         {UserData.isSuccess && deviceModel.isSuccess && stateUseTenantRole ?
          (
            <>
              <div className="mt-3 text-center">
                <img src="/img/SG-A-SG-T.png" alt="sa-a" title="sa-a" width="80%" />
                <h4 className="bold mt-3" dangerouslySetInnerHTML={{__html: t("start.title")}} />

                  {stateUseTenantRole === TypeOfUser ?
                  (<>

                    {!stateUseTenantId && tenantAccess && tenantAccess.length > 1 &&
                      <>
                            {stateTenantLoaded ?
                            (
                              <div className="mt-5 w-100 ">
                                <div className=""><i className="fa fa-angle-down"></i> {t("start.chooseAccount")}</div>
                                {stateTenant &&
                                  stateTenant.map((item, i) => {
                                      const tenantId = (item && item.tenantId) ? item.tenantId:'';
                                      const name = (item.name) ? item.name:'Namn saknas - '+tenantId;
                                      const viewname = (item && item.company) ? item.company:name;
                                      const country = item.country;
                                      return (
                                        <div key={i} onClick={()=>SelectTenant(tenantId, viewname, country)} className="flex pointer mt-2 content-box p-3 text-big" >
                                          <div className="ml-1 w-90 text-left">{viewname}</div>
                                          <div className="w-10 text-right bold"><i className="fa fa-angle-right"></i></div>
                                        </div>
                                      )
                                  })
                                }
                              </div>
                            ):(
                              <div className="mt-2">
                                <span className="spinner-border"></span>
                              </div>
                            )
                          }
                      </>
                    }

                    {stateChangeTenant === false && tenantId &&
                      <>
                        <div ref={searchbody}></div>
                        {tenantAccess && tenantAccess.length > 1 ?
                          (
                          <div onClick={()=>ChangeTenant()} className="text-center w-100 pointer mt-5">
                            <div className="bold">{tenantName}</div>
                            <i className="fa fa-angle-left"></i> <i className="fa fa-angle-left"></i>  {t("start.changeAccount")}
                          </div>
                          ):(
                            <div className="mt-4">
                              {t("start.account")}
                              <div className="bold">{tenantName}</div>
                            </div>
                          )
                        }

                        <Link to="/install" className="btn btn-primary relative p-4  block w-100 mt-4">{t("start.createNewFacility")} <div className="fieldicon text-white pb-2"><i className="fa fa-plus pointer" aria-hidden="true"></i></div></Link>

                        <div className="search-obj relative block w-100 btn btn-border mt-4">
                          <div onClick={(e)=>SearchAction(e)} className="fieldicon"><i className="fa fa-search pointer" aria-hidden="true"></i></div>
                          <input defaultValue={searchQueryStorage} onClick={()=>ScrollToTopSearch()} onChange={(e)=>search(e)} onKeyPress={event => {
                                                              if (event.key === 'Enter') {
                                                                 SearchAction(event)
                                                              }
                                                            }} className="w-100 no-border text-center" name="search" id="search" type="search" placeholder={t("start.searchExistingFacility")} />
                        </div>

                        <SearchAssets tenantId={stateUseTenantId} search={stateSearch}/>
                        <div className="mt-10"></div>
                      </>
                    }


                  </>):(<>
                      {stateUseTenantRole &&
                        <div className="mt-4 text-gray">
                         <h4 className="text-danger">{t("start.permissionDenied")}</h4>
                         {t("start.contactSupplierForSupport")}
                        </div>
                      }
                  </>)
                }

                {tenantUserName &&
                  <div className="mt-10 mb-6 d-flex flex-column w-30 mx-auto" style={{gap: "10px"}}>
                    <span>
                      <i className="fa fa-user-o" aria-hidden="true"></i> {t("start.user")}
                    </span>
                    {tenantUserName}
                    <LanguagePicker/>
                    <button onClick={()=>doLogout()} className="btn btn-danger">
                      <i className="fa fa"></i> {t("start.logOut")}
                    </button>
                  </div>
                }
                
              </div>


              <GuideInfo.Footer />

            </>
          ):(
            <>
              <div className="loading-content hp-Loading mt-6"><span className="spinner-border"></span></div>
            </>
          )
        }

      </div>
  );
}



function MatchQuery(item, q) {
  var resp = false;
  var query = q.toLowerCase().replace(/\s/g, "");
  const minLength = 2;

  var find_assetId;
  if(query.length > minLength && item.assetId) {
        find_assetId = item.assetId.toLowerCase().includes(query);
  }

  var find_meterId;
  if(query.length > minLength && item.meterId) {
        find_meterId = item.meterId.toLowerCase().includes(query);
  }

  var find_clientId;
  if(query.length > minLength && item.clientId) {
        find_clientId = item.clientId.toLowerCase().includes(query);
  }

  var find_meterGridArea;
  if(query.length > minLength && item.meterGridArea) {
        find_meterGridArea = item.meterGridArea.toLowerCase().includes(query);
  }

  var find_deliveryArea;
  if(query.length > minLength && item.deliveryArea) {
        find_deliveryArea = item.deliveryArea.toLowerCase().includes(query);
  }

  var find_updatedBy;
  if(query.length > minLength && item.updatedBy) {
        find_updatedBy = item.updatedBy.toLowerCase().includes(query);
  }

  // address search
  var addressObjects = '';
  var find_address;
  if(query.length > minLength && item.address && item.address.address1) {
        find_address = item.address.address1.toLowerCase().includes(query);
        addressObjects = item.address.address1.toLowerCase();
  }

  var find_zip;
  if(query.length > minLength && item.address && item.address.zip) {
        find_zip = item.address.zip.toLowerCase().includes(query);
        addressObjects = addressObjects+item.address.zip.toLowerCase();
  }

  var find_city;
  if(query.length > minLength && item.address && item.address.city) {
        find_city = item.address.city.toLowerCase().includes(query);
        addressObjects = addressObjects+item.address.city.toLowerCase();
  }

  var find_addressAll;
  if(query.length > minLength && addressObjects) {
        addressObjects = addressObjects.replace(/\s/g, "");
        find_addressAll = addressObjects.includes(query);
  }

  var find_name;
  if(query.length > minLength && item.name) {
         find_name = item.name.toLowerCase().includes(query);       
  }

  // match
  if(find_name || find_assetId || find_meterId || find_clientId || find_meterGridArea || find_deliveryArea || find_updatedBy || find_address || find_zip || find_city || find_addressAll) {
    resp = true;
  }

  return resp;
}

function SearchAssets(props) {
  const query = (props.search) ? props.search:'';
  const {lng,t} = useTranslation();
  const langCode = lng === "sv" ? "SE" : "FI";

  const { callAPI } = useAPI();
  // get assets
  const limit = '?limit=10000';
  const fetchItemAssets = async () => {
      const data = await callAPI({
        resource: `/tenant/${props.tenantId}/asset/${limit}`,
        method:'get',
      });
      return data;
  };
  const assetResp = useQuery('AssetItem', fetchItemAssets); 
  const itemSorted = (assetResp && assetResp.data && assetResp.data.objects) ? Functions.SortList(assetResp.data.objects, 'updatedDate', true):[];
  //console.log("fetchItemAssets",itemSorted);

  if(assetResp.data && assetResp.data.objects){
    console.log('assets ',assetResp.data.objects.length);
  }
  return(
    <div className="">
      {assetResp.isSuccess && assetResp.data && assetResp.data.objects ? 
        (<> 
          {query && query.length > 2 &&
            <div className="p-2"><i className="fa fa-angle-down"></i> {t("start.searchResults")}</div>
          }

            {itemSorted.map((item, i) => {
                const updatedDate = (item.createdDate) ? Functions.UTCtoLocalTime(item.updatedDate):'';
                return (<div key={i}>
                          {MatchQuery(item, query) &&
                            <div className="content-box w-100 p-1 mb-2 text-left">
                                <Link to={"/install?step=1&id="+item.assetId+"&language="+langCode} className="w-100 text-white">                              
                                  <div className="d-flex p-0">
                                      <div className="w-50 bold p-1">
                                          {item.name && 
                                          <>
                                            <div className="">{item.name}</div>
                                          </>
                                          }
                                          {item.address && item.address.address1} {item.address && item.address.zip}
                                          <div className="no-wrap">
                                            {item.address && item.address.city}
                                          </div>
                                      </div>
                                      <div className="mr-2 w-40 text-left p-1">
                                        {item.meterId}<br/>
                                        {item.clientId}
                                        <div className="text-grey no-wrap text-sm">
                                          {t("start.lastChanged")}<br/>
                                          {updatedDate}
                                        </div>
                                      </div>
                                      <div className="ml-auto w-2 p-1 text-sm bold text-right">{item.deliveryArea} {item.meterGridArea}</div>
                                      <div className="ml-auto w-5 p-1 text-sm text-right">{item.verified && <i className="fa fa-check text-success"></i>}</div>
                                  </div>
                                </Link>
                            </div>
                          }
                </div>)
            })
         }   
        </>): t("start.noUnitsFound")
      }

    </div>
  );
}

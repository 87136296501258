const translation = {
  translation: {
    start: {
      changeAccount: "Vaihda tili",
      chooseAccount: "Valitse tili",
      contactSupplierForSupport: "Ota yhteyttä toimittajaan saadaksesi tukea.",
      createNewFacility: "Luo uusi laitteistö",
      lastChanged: "Viimeksi muutettu",
      logOut: "Kirjaudu ulos",
      nameMissing: "Nimi puuttuu",
      noUnitsFound: "Yksiköitä ei löytynyt",
      permissionDenied: "Lupa evätty",
      searchExistingFacility: "Etsi olemassa oleva laitteisto",
      searchResults: "Etsi tulokset",
      user: "Käyttäjä",
      title: "Easyserv-asennusportaali<br/>SG-A & SG-T asennus",
      account: "Tili",
    },
    guideDoc: {
      startInstallation: "Aloita asennus",
      nextStep: "Seuraava vaihe",
      introduction: "Johdanto",
      hardwareRemoval: "Purkuvaihe",
      hardwareInstallation: "Laitteiston asennus",
      postInstallationReset: "Nollaus asennuksen jälkeen",
      previous: "Edellinen",
    },
    home: {
      install: "Asenna",
      installerLogin:
        "Asentajana kirjaudut sisään tästä <br/>lisätäksesi ja hallitaksesi laitoksia.",
      settings: "Asetukset",
      pairDevice:
        "Liitä laite Bluetoothin kautta <br/>tehdäksesi asetuksia paikan päällä.",
      pair: "Yhdistä",
      login: "Kirjaudu sisään",
    },
    install: {
      supportTitle: "Tuki",
      supportDescription:
        "<p>Tarvitsetko apua asennuksessa?<br/> Voit ottaa meihin yhteyttä.</p><p>Aukioloajat: 08.00 - 16.00 (arkipäivät)<br/>Puh: 035-710 11 50<br/>Sähköposti: support@easyserv.se</p>",
      mountainLandLake: "Vuori/maa/järvi",
      airWater: "Ilma/vesi",
      exhaustAir: "Poistoilma",
      unspecifiedType: "Tyyppi määrittelemätön",
      account: "Tili",
      address: "Osoite",
      addTempSensor: "Lisää lämpötila-anturi",
      areaNotFound:
        "Sähköalueita ei löytynyt.<br/>Tarkista postinumero ja antamasi osoite.",
      asset: "Laitos",
      assetId: "Laitoksen tunnus",
      assetIdEan: "Laitoksen tunnus / EAN",
      assetIdMissing: "Laitoksen tunnus puuttuu",
      back: "Takaisin",
      busyHardwareId: "Laitetunnus on varattu",
      cancel: "Peruuta",
      change: "Muuta",
      checkboxError:
        "Valintaruutu on valittava<br/> tai muu virhe on tapahtunut.",
      city: "Kaupunki",
      confirm: "Vahvista",
      confirmCorrect: "Vahvistan, että kaikki tiedot ovat oikein",
      confirmDelete:
        "<strong>Haluatko varmasti poistaa lämpötila-anturin?</strong><br/>Tunnus: {{tempSensorId}}",
      confirmExit: "Haluatko varmasti poistua?",
      confirmExternalInputs: "Vahvista ulkoiset sisääntulot",
      confirmInstallation: "Vahvista asennus",
      connectedCables: "Kytketyt kaapelit",
      connectHardware: "Yhdistä laitteisto",
      connectingSensor: "Yhdistetään lämpötila-anturia<br/> odota hetki",
      connectingSGA: "Yhdistetään SG-A<br/> Odota hetki",
      continue: "Jatka",
      continueWithInstallation: "Jatka asennusta",
      createAsset: "Luo laitos",
      done: "Valmis!",
      electricityArea: "Sähköalue",
      enterAddress: "Syötä laitoksen osoite",
      enterHardwareID: "Syötä laitetunnus",
      enterID: "Jos skannaamisessa on ongelmia, syötä tunnus (18 merkkiä)",
      enterSensorId: "Syötä anturin tunnus",
      error: "Virhe!",
      errorOccurred: "Tapahtui virhe!",
      externalInputNote:
        "<strong>HUOM!</strong> <br/> Jos vain yksi ulkoinen sisääntulo on käytössä,<br/> käytä keltaista/ruskeaa johdinta,<br/> älä kytke valkoista/punaista johdinta.",
      finish: "Lopeta",
      finishAndSave: "Valmista ja tallenna",
      getPos: "Hae sijainti",
      gridArea: "Verkkoalue",
      guide: "Opas",
      hardwareId: "Laitetunnus",
      hardwareSGA: "Laitteisto SG-A",
      heatPump: "Lämpöpumppu",
      howManyExternalInputs: "Kuinka monta ulkoista sisääntuloa on kytketty?",
      installation: "Asennus",
      installationCheck: "Asennuksen tarkistus",
      installationComplete: "Asennus on valmis!",
      installer: "Asentaja",
      installWithoutSGT: "Asenna ilman SG-T:tä",
      invalidAssetInput: "Virheellinen laitoksen tunnus/EAN",
      manufacturer: "Valmistaja",
      mapOverlay: "(Karttapeite, varmista että se on oikein)",
      model: "Malli",
      multipleFailures:
        "Jos yhteys epäonnistuu useita kertoja,<br/>voit siirtyä ja suorittaa asennuksen loppuun.",
      nextStep: "Seuraava vaihe",
      no: "Ei",
      noSensor: "Ei anturia",
      pairingFailed: "Pariliitos epäonnistui!",
      pairingFailedTroubleshoot:
        "Tarkista, että lämpötila-anturissa on paristot<br/> ja että sinulla on internet-yhteys. <br/>Yritä uudelleen vähintään 2 kertaa ennen kuin jatkat.",
      pairingInstructions1:
        "Paina nappia 2-3 kertaa, katso kuva<br/> HUOM! Paina nopeasti, älä pidä nappia pohjassa<br/>",
      pairingInstructions2: "Odota vahvistusta, kestää 10-20 sekuntia",
      pairTempSensor: "Pariliitä lämpötila-anturi<br/> SG-T",
      postalCode: "Postinumero",
      powerCycleSGA:
        "Kokeile myös irrottaa SG-A:sta virta<br/> ja kytkeä se uudelleen ja odota, kunnes vilkkuminen loppuu, ja yritä uudelleen.",
      prepareInstallation: "Valmistele asennus",
      proceedWithoutSGT:
        "Jos SG-T puuttuu tai on jo asennettu, voit siirtyä seuraavaan vaiheeseen.",
      remove: "Poista",
      scanQRCodeToAddSensor:
        '<strong>Skannaa SG-T:n QR-koodi takaa lisätäksesi anturin.</strong><br/><i className="text-grey">Jos skannaamisessa on ongelmia, syötä tunnus (12 merkkiä)</i>',
      scanQRCodeToConnect: "Skannaa SG-A:n QR-koodi pohjasta yhdistääksesi.",
      search: "Etsi",
      searchHeatPumpModel: "Etsi lämpöpumpun mallia täältä...",
      selectHeatPump: "Valitse lämpöpumppu",
      sensor: "Anturi",
      sensorConnected:
        "Lämpötila-anturi on nyt yhdistetty<br/>ja voit jatkaa eteenpäin.",
      sensorMissing: "Anturi puuttuu!",
      sgaConnected: "SG-A on nyt yhdistetty<br/>ja voit jatkaa eteenpäin.",
      sgaConnectionFailed: "SG-A:n yhdistäminen epäonnistui!",
      SGARestarts: "SG-A käynnistyy nyt uudelleen, kestää noin 15-30 sekuntia",
      SGATroubleshoot:
        'Varmista, että SG-A:lla on 4G-peittoa <br/> ja että se palaa Vihreänä <span className="color-green text-big bold">&#9473;</span>&nbsp; tai Oranssina <span className="color-orange text-big bold">&#9473;</span>. <br/>Kokeile myös irrottaa virta SG-A:sta <br/>ja kytkeä se uudelleen ja odota vilkkumisen loppumista ja yritä uudelleen.',
      size: "Koko",
      skipGuide: "Ohita opas",
      stepDescription:
        "Tässä vaiheessa SG-A laite yhdistetään ohjaustietokantaan, joka antaa taukokäskyjä lämpöpumpulle.",
      streetAddress: "Katuosoite",
      success: "Onnistui",
      support: "Tuki",
      techError: "Internet- tai tekninen virhe. Yritä uudelleen",
      tempSensorAdded: "Lämpötila-anturi lisätty",
      tempSensorSGT: "Lämpötila-anturi SG-T",
      test4GCoverage: "Testaa 4G-peittoalue",
      tryAgain: "Yritä uudelleen",
      type: "Tyyppi",
      undo: "Kumoa",
      verifyDetails:
        'Varmista, että alla olevat tiedot ovat oikein<br/> ja paina sitten "Vahvista"',
      writeAssetId:
        "Kirjoita asiakkaan<br/>laitostunnuksen/EAN:n 12 viimeistä numeroa.",
      yes: "Kyllä",
    },
    guideInfo: {
      checkAvailability: "Varmista, että seuraavat asiat ovat saatavilla",
      heatPumpUnit: "SG-A, yksikkö joka kytketään lämpöpumppuun",
      sgTHouseTempSensor:
        "SG-T, lämpötila-anturi, joka sijoitetaan talon keskelle",
      powerAdapterToSGA: "Virtasovitin SG-A:lle",
      cableConnectingSgaHeatPump: "Kaapeli SG-A:n ja lämpöpumpun välillä",
      checkPowerOutlet:
        "Varmista, että lähistöllä on 230V pistorasia, muuten vedä jatkojohto lähimmästä pistorasiasta",
      switchOnSGA:
        'Aseta SG-A:n kytkin asentoon "On" (paina kytkintä lähimpään kulmaan)',
      insertBatteriesSensor:
        "Aseta mukana tulevat 3 paristoa SG-T-lämpötila-anturiin irrottamalla takakansi ja asettamalla 3 paristoa sisään.",
      placeUnitConnectPower:
        "Aseta yksikkö paikalleen, jossa se on lopullisesti asennettuna. Kytke virtasovitin SG-A:han ja odota, että LED-valo palaa tai vilkkuu VIHREÄNÄ, kestää 10-30 sekuntia.",
      check4GCoverage:
        "Tarkista 4G-yhteys painamalla 4G-painiketta sinisen nuolen osoittamassa reiässä (paina esim. klemmarilla):",
      greenGoodCoverage:
        "Vihreä: <strong>Hyvä peittoalue</strong>, siirry seuraavaan vaiheeseen",
      orangeSufficientCoverage:
        "Oranssi: <strong>Pitäisi toimia</strong>, siirry seuraavaan vaiheeseen",
      redInsufficientCoverage:
        "Punainen: <strong>Ei riittävää yhteyttä</strong> vakaaseen toimintaan",
      redLedRelocate:
        "Punaisen LED-valon ollessa päällä, yritä siirtää laitetta toiseen paikkaan. Vältä sijoittamista esim. suojapeltien tai muiden metalliesineiden taakse. Voit myös kokeilla eri asennuskulmaa (esim. pystyasennossa vaakasijaan verrattuna). Testaa uusi sijainti ja paina painiketta kuten yllä olevassa kuvassa. Vaikka väri muuttuu oranssiksi, testiä voi kokeilla uudelleen vihreän merkinnän saamiseksi.",
      verifyInstallation:
        "Nyt on aika tarkistaa ja vahvistaa asennus seuraavasti:",
      sgAMustBeConnected:
        'SG-A:n tulee olla kytketty ja käynnissä, ja <span className="color-green text-big">&#9473;</span> vihreän LED-valon tulee palaa.',
      verifyOutdoorTemp:
        "Varmista (lämpöpumpusta), että nykyinen ulkolämpötila näkyy oikein.",
      doFollowing: "Tee seuraavat toimet:",
      simulateOutdoorTemperature:
        "- Paina ja pidä sinistä painiketta alhaalla kolme sekuntia, ja ulkolämpötila simuloituu lämpöpumpussa kolmessa vaiheessa 15 sekunnin välein. Ensin näkyy +10 astetta, sitten 0 astetta ja lopuksi -10 astetta.",
      incorrectValuesSwapLeads:
        'Jos arvot ovat täysin vääriä, vaihda punainen ja harmaa johto keskenään ja kokeile uudelleen. Jos LED-valo vilkkuu oranssina, SG-A:n kytkin ei ole "On"-asennossa.',
      externalInputsActivation:
        "Heti yllä olevan ulkolämpötilatestin jälkeen ulkoiset sisääntulot aktivoituvat lämpöpumpussa myös 15 sekunnin välein. Varmista lämpöpumpun näytöltä, että se reagoi tähän signaaliin.",
      attention: "HUOM!",
      importantTurnOffMainSwitch: "Tärkeää katkaista päävirtakytkin",
      heatPumpBeforeInstall:
        "lämpöpumpusta ennen asennuksen aloittamista.<br/> (jos päävirtakytkintä ei ole, katkaise sulakkeet)",
      understood: "Ymmärretty",
      noGuide: "Opas puuttuu",
      installationGuideMissing:
        "Lämpöpumppumallista puuttuu asennusohje <strong>{{model}}</strong>.<br/> Ota yhteyttä, jos tarvitset apua asennuksessa.<br />",
      disassembleForInstall: "Purkaminen asennusta varten",
      installHardware: "Laitteiston asennus",
      postInstallReset: "Kasaus asennuksen jälkeen",
      deployment: "Käyttöönotto",
    },
  },
};

export default translation;
